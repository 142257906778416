// # Header
// ## Logo
$configuration-logo-width: 229px;
$configuration-logo-width__md_down: 129px;
$configuration-logo__stuck-width: 129px;
$configuration-logo__stuck-width__md_down: $configuration-logo-width__md_down;
$configuration-logo__portrait-width: 52px;
$configuration-logo__stuck__portrait-width: 52px;
// ### Logo aspect ratio variables. They have to be an integer
$configuration-logo-image-height: 80;
$configuration-logo-image-width: 229;
$configuration-logo-image__portrait-height: 150;
$configuration-logo-image__portrait-width: 109;
$configuration-logo__stuck-image__portrait-height: 130;// reduce the height to hide the bottom of a logo

// # Buttons
$configuration-button_main-border_width: 3px;
$configuration-button_main_border_radius: 4px;
$configuration-button_main__disabled-opacity:1;
$configuration-button_alt_border_radius:4px;

// # Links
$configuration-link_2-underline_offset: 0;

// # Form
$configuration-form-input-font: 700 16px/22px $font-main;
$configuration-form-select-font: $configuration-form-input-font;

// # Product tile
$configuration-product_tile-cta_type: alt;
$configuration-product_tile-description-font_weight: normal;
$configuration-product_tile-description-letter_spacing: 0.2px;
$configuration-product_tile-name-font: 700 20px $font-alt ;
$configuration-product_tile-name-font_size__small: 16px;
$configuration-product_tile-name-letter_spacing: 1px;
$configuration-product_tile-name-transform: uppercase;

// # Product badge
$configuration-product_badge-points-line_height: 0.8;
$configuration-product_badge-border_radius: 0;
$configuration-product_badge-font: 700 12px/24px $font-main;
$configuration-product_badge-font_size__small: 9px;

// # Promo
$configuration-promo-border_radius: 4px;

// # Product quantity stepper
$configuration-product_quantity-border_width: 2px;

// # PLP & SEARCH
$configuration-filter_selected-border_radius: 3px;
$configuration-noof-grid-template-columns:3;

// # Age gate
$configuration-age_gate-background_image: url('../images/age-gate-background.jpg');
$configuration-age_gate__mobile-background_image: url('../images/age-gate-background.jpg');
$configuration-age_gate-button_type: main;
$configuration-age_gate-button_modification: default;

// # Checkout
// ## Gift message
$color-checkout-gift_message_label: $color-accent;

// ## Confirmation page
$configuration-confirmation-account-background_image: url('../images/age-gate-background.jpg');
$configuration-confirmation-account-background_image__small: url('../images/age-gate-background.jpg');
$configuration-confirmation_create_account-button_modifications: default;

// # Error pages
$configuration-error_page__404-background_image: url('../images/age-gate-background.jpg');
$configuration-error_page__404__mobile-background_image: url('../images/age-gate-background.jpg');

// ## Global header Navigation
$configuration-category_navigation-link-letter_spacing: 0;
$configuration-category_navigation-link-font: 700 24px/40px $font-main;
$configuration-category_navigation-link-text_tranform: capitalize;

// # Cart product details
$configuration-cart_product_details-name-font:700 16px/24px $font-alt;
$configuration-cart_product_details-name-letter_spacing:1px !default;
$configuration-cart_product_details-name-transform: uppercase !default;