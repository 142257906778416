// # Applied colors palette #
//============================================
// ## Main colors ##
$color-primary: #423f44;
$color-primary-background: #fff;
$color-select-option: #0c0c0c;
$color-error: #CA1E36;
$color-success: #008A4C;//#37a10a;
$color-promo-background: #F7F6F7;
$color-error-background: #faeded;
$color-notification-background: rgba(199,195,202,1);
$color-input-border: #592761; //old #3F2B4F
$color-accent: #805080;

$color-mauve-accent: #805080;
$color-darkpink-accent: #B40766;
$color-pink-accent: #E5315C;
$color-purple-accent: #5B5287;
$color-barefoot-purple: #592761; //old #3F2B4F

// ## Shades ##
$color-shade_1: #FFFFFF;
$color-shade_2: #C1C6C9;
$color-shade_3: #C7C3CA;
$color-shade_4: #75717C;
$color-shade_5: #423f44;
$color-shade_6: #000000;

// ## GH/GF colors ##
$color-header_text: $color-primary;
$color-header_alt: $color-primary-background;
$color-footer_text: $color-primary;
$color-footer_alt: $color-primary;

// ## Buttons ##
// ### Button Main ###
$color-button_main-background: $color-darkpink-accent;
$color-button_main-border: $color-darkpink-accent;
$color-button_main-text: $color-primary-background;
$color-button_main__hover-background: $color-primary-background;
$color-button_main__hover-border: $color-darkpink-accent;
$color-button_main__hover-text: $color-darkpink-accent;
$color-button_main__inversed-background: $color-primary-background;
$color-button_main__inversed-border: $color-darkpink-accent;
$color-button_main__inversed-text: $color-darkpink-accent;
$color-button_main__inversed__hover-background: $color-darkpink-accent;
$color-button_main__inversed__hover-border: $color-darkpink-accent;
$color-button_main__inversed__hover-text: $color-primary-background;
// ### Alt ###
$color-button_alt-background: $color-primary-background;
$color-button_alt-border: $color-darkpink-accent;
$color-button_alt-text: $color-darkpink-accent;
$color-button_alt__hover-background: $color-darkpink-accent;
$color-button_alt__hover-border: $color-darkpink-accent;
$color-button_alt__hover-text: $color-primary-background;
$color-button_alt__inversed-background: $color-darkpink-accent;
$color-button_alt__inversed-border: $color-darkpink-accent;
$color-button_alt__inversed-text: $color-primary-background;
$color-button_alt__inversed__hover-background: $color-primary-background;
$color-button_alt__inversed__hover-border: $color-darkpink-accent;
$color-button_alt__inversed__hover-text: $color-darkpink-accent;
// ## Badges ##
$color-badge-1: #d8d8d8;
$color-badge-2: $color-purple-accent;
//============================================

// Semantic colors mapping to components
// # COMMON #
$color-text__dark: $color-primary-background;
$color-text__light: $color-primary;
$color-overlay-background: $color-shade_5;
$color-text-dimmed: $color-shade_4;

// # COMPONENTS #
$color-product_image-background: $color-primary-background;

// # PDP #
$color-product_tabs-item-border: $color-shade_3;
$color-product_tabs-border: $color-shade_3;
$color-product_gallery-thumb-border:transparent; //rgba($color-shade_5, 0.35);
$color-product_gallery-thumb__selected-border:$color-mauve-accent; //rgba($color-primary, 0.35);

// # PLP #
$color-filters-label: $color-barefoot-purple;
$color-filters-border: $color-mauve-accent;
$color-sorting-label: $color-purple-accent ;
$color-sort: $color-purple-accent ;


// ## MESSAGE ##
$color-message-text__inversed: $color-primary;

// ## Product tile ##
$color-product_tile-promo-background: #F7F6F7;
$color-product_tile-promo: $color-barefoot-purple;
$color-product_tile-description: $color-shade_4;

// ## PRODUCT PRICE ##
$color-product_price-old: $color-shade_4;

// ## Header ##
$color-header-background: $color-primary-background;
$color-header__transparent-text: $color-header_text;
$color-header__transparent__inverted-text: $color-header_alt;
$color-header__transparent-background-border: rgba($color-shade_4, 0.15);
$color-header_promo-background: #F7F6F7;
$color-header__transparent-header_promo-background: $color-promo-background;
$color-navigation_flyout-background : $color-primary-background;
$color-hamburger_menu-background: $color-primary-background;
$color-hamburger_back_link-background: $color-primary-background;
$color-hamburger_account-background: $color-primary-background;
$color-cookies-background: $color-shade_5;
$color-header_promo__transparent_header-background: $color-badge-2;
$color-main_nav-divider: #C7C3CA;//$color-shade_5;
$color-mobile_navigation-categories_divider: #C7C3CA;
$color-mobile_navigation-section_divider: $color-shade_4;

// ## Footer ##
$color-footer-border: $color-shade_3;
$color-footer-background: $color-primary-background;
$color-footer-email_subscription-background: rgba($color-shade_6, 0.78);

// ## NOTIFICATION ##
$color-notification-text: $color-primary;

// ## TABS ##
$color-tabs-border: $color-shade_3 ;
$color-tabs-text: $color-primary ;
$color-tabs-text__active: $color-barefoot-purple;

// # ERROR PAGES #
$color-error_410-text: $color-text__light;

// # PAGE DESIGNER #
$color-banner_2_region: $color-primary;

// # PDP #
$color-product_gallery-thumbs_control-background: rgba($color-shade_6, 0.9);



// ## PRODUCT WISHLIST CTA ##
$color-wishlist: $color-mauve-accent; //$color-primary;
$color-wishlist-active: $color-mauve-accent ;//$color-accent;

// ## FORMS ##
$color-form-input: #423F44;
$color-form-input-background: $color-primary-background;
$color-form-input-border: $color-purple-accent;
$color-form-input__disabled: #C7C3CA;
$color-form-label: $color-barefoot-purple;
$color-form-caption: #75717C;


// ## PRODUCT QUEANTITY STEPPER ##
$color-quantity: $color-purple-accent;
$color-quantity-background: $color-primary-background;

$color-svg-icon: #805080;

// ## PRODUCT AVAILABILITY  ##
$color-product_availability-in_stock: $color-success;
$color-product_availability-low_stock: $color-success;
$color-product_availability-out_of_stock: $color-error;

// ## SWATCHES ##
$color-swatch_title: $color-purple-accent;
$color-swatch_border: $color-shade_3 ;
$color-swatch_border-selected: $color-mauve-accent;

// ## ACCOUNT NAVIGATION ##
$color-account_nav-item__active-text: $color-barefoot-purple;
$color-account_nav-item__active-bg: rgba(#C7C3CA,0.15);

// # MY ACCOUNT #
$color-account_info_tile-bg: rgba($color-shade_3,0.15);
$color-state__hover-background: rgba(#C7C3CA,0.15);
$color-shipping_panel-divider:#C7C3CA;


// # STORE LOCATOR #
$color-store_locator-border: rgba($color-shade_3,0.15);
$color-store_locator-background: $color-primary-background;
$color-store_locator-close_background: rgba($color-shade_3,0.15);
$color-store_locator-close_text: $color-primary;
$color-store_locator-nav_background: $color-primary-background;
$color-store_locator-nav_text: $color-shade_3 ;
$color-store-border:  rgba($color-shade_3,0.15);
$color-store-hover: rgba($color-shade_3,0.15);

// ## MESSAGE ##
$color-message-background: $color-primary-background;
$color-message-border: $color-purple-accent;
$color-message-text: $color-barefoot-purple;
$color-message-text__inversed: $color-primary;
$color-message-background__inversed: rgba($color-primary-background, 0.15) ;

// ## LINKS ##
$color-link: $color-mauve-accent;
$color-link__visited: $color-mauve-accent;
$color-link__disabled: $color-shade_4;


// ## ACCORDION ##
$color-accordion-item-border: #C7C3CA;

// ## PROGRESS BAR ##
$color-progress-bg: $color-primary-background;
$color-progress-value-bg: $color-mauve-accent;


// ## BREADCRUMBS ##
$color-breadcrumb-current: $color-shade_5;
$color-breadcrumb-separator_bg: $color-mauve-accent;
$color-breadcrumb-link_color:  $color-mauve-accent;

// ## PRODUCT TABLE ###
$color-product_table-separator: #C7C3CA;
$color-product_table-head_text: $color-barefoot-purple;
$color-product_table-sku: #75717C;
$color-product_table-attributes_separator: #C7C3CA;

// ## ORDER SUMMARY ##
$color-order_summary-border: $color-shade_3;
$color-order_summary-separator: $color-shade_3;

// ## CHECKOUT ##
$color-checkout-card_icon: $color-shade_5;
$color-checkout-gift_message_label: $color-shade_5;

// ## CAROUSEL ##
$color-carousel_control:$color-mauve-accent;
$color-carousel_pagination_control: $color-primary-background;
$color-carousel_pagination_control__active: $color-mauve-accent;