/*md
@no-stat

# Media queries (breakpoints)

We have a `media` mixin for make it easier to implement responsive styling via media queries.

You can nest them right within other blocks of CSS, which puts the properties and values you are changing right next
to each other.

That creates an obvious connection between them, which is a much nicer authoring experience than trying to maintain
those changes separated by tons of other code or in another different file.

## Configuration

`media` mixin works with `$media` map where `media-name: media query`

This is how `$media` map looks:

```scss
$media: (
	sm: 'screen and (max-width: 767px)',
	md: 'screen and (min-width: 768px) and (max-width: 1199px)',
	lg: 'screen and (min-width: 1200px)',
	xl: 'screen and (min-width: 1201px)',
	md-up: 'screen and (min-width: 768px)',
	md-down: 'screen and (max-width: 1023px)',
	lg-up: 'screen and (min-width: 1200px)',
	lg-down: 'screen and (max-width: 1440px)'
);
```

## Usage

Here is how to use `media()` mixin:

```scss
.b-block {
	// styles outside of a media query

	@include media(sm) {
		// styles for "s" viewports
	};

	@include media(md-up) {
		// styles for "m" and "l" viewports
	};
}
```

Simply edit this file and add your own media queries to `$media` map.

*/
@import url("https://use.typekit.net/mub2bfy.css");
@import url("https://use.typekit.net/zwo7lre.css");
/*md
@no-stat

# Rh (Indents rhythm)

Initial assumption is that Designs should follow the agreed Rhythm.

The main puprose of Rhythm function is to soothe inconsistencies of design for vertical and horizontal indents if such appear.

If we have agreed on Rhythm = 5 and we meet padding or margin 22px in design - we should round it to rh(4)=20px

Rhythm function can be avoided if some measurements are specific, i.e.:
	-icon size, blocks width/height are specific values
	-form elements paddings/margins/sizes are usually specific values
	-positioning of elements might have nothing to do with parent paddings

## Usage

```scss
.component {
	@include rh(2); // => 10px
	@include rh(2 4 0); // => 10px 20px 0
}
```
*/
/* stylelint-disable */
/* stylelint-enable */
/*md
@no-stat

# Z-indexes

This is map of z-indexes used on project. To easily work with z-indexes and avoid many issues related to this,
you need to not use z-index directly, but instead use z() function to get z-index value from this map.

## Usage

### Global components

```scss
// settings.scss
$z-indexes: (
	content: (),
	popup-menu: ()
);

// component.scss
.b-pop_up-menu {
	//...
	z-index: z(popup-menu);
}
```

### Inside a component

```scss
// settings.scss
$z-indexes: (
	components: (
		component_name: (),
		checkbox: (
			before: (),
			after: (),
			icon: (),
		)
	),
	content: (),
	popup-menu: ()
);

// component.scss
.component_name {
	position: relative;
	z-index: z(components, component_name);
}

// checkbox.scss
.checkbox {
	&-icon {
		z-index: z(components, checkbox, icon);
	}
}
```

*/
/*md
@no-stat

# grid-* (grid config get functions)

This functions designed to get parameters from grid configuration config and
use it for creating grids or reuse grid configuration into different components.

* `grid-gutter`
* `grid-columns`
* `grid-margin`

## Usage

```scss

// Configuration:

$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	),
	altered: (
		grid-columns: ('xl': 10,   'lg': 10,   'md': 10,   'sm': 6),
		grid-gutter:  ('xl': 10px, 'lg': 10px, 'md': 10px, 'sm': 10px),
		grid-margin:  ('xl': 40px, 'lg': 30px, 'md': 30px, 'sm': 20px),
	)
);

// Usage:

.component {
	padding: grid-gutter('lg'); // => grids -> 'default' -> grid-gutter -> lg = 20px
	padding: grid-gutter('lg', 'altered'); // => => grids -> 'altered' -> grid-gutter -> lg = 10px
}

.component-b {
	margin: grid-margin('lg');
	margin: grid-margin('lg', 'altered');

	@include media(sm) {
		margin: grid-margin('sm');
	}
}

.component-c {
	width: percentage(grid-columns('lg') / 4);

	@include media(sm) {
		width: percentage(grid-columns('sm') / 2);
	}
}
```
*/
/*md
@no-stat

# aspect-ratio

This function used to get percentage value of aspect ratio color to use in `padding` to
create container for images.

This technique used to prevent content bouncing during load and create layout shifts.

Calculation. 16:9 Aspect Ratio would result `(9 / 16) * 100 = 56.25%`.

See proposed [specs](https://drafts.csswg.org/css-sizing-4/#aspect-ratio)

## Arguments

```
$width - width of element
$height - height of element

=> percentage

aspect-ratio($width, $height)
```

## Usage

```scss
.component {
	padding-bottom: aspect-ratio(16, 9);
	padding-bottom: aspect-ratio(1920, 1080);
	padding-bottom: aspect-ratio(1920px, 1080px);
}

.b-suggestions-item_image {
	@include g-image_container(_container, aspect-ratio(16, 9));

	img {
		@include g-image_container(_img);
	}
}
```

*/
/*md
@no-stat

# Hide

This mixin is especially useful for hiding text
or visually hide needed elements

Here is a list of parameters you can use:

* text - helps to hide text without loosing visibility for parsers
* visually - like for text but for the whole element

Second parameter is for the `!important` keyword.

## Usage

```scss
.component {
	@include hide(visually);
}

.h-hide_vis {
	@include hide(visually, true);
}
```
*/
/*md
@no-stat

# Lines clamp

This mixin is useful for adding ... for text with more than 1 line

## Usage

```scss
.component {
	@include line-clamp;
}
```
*/
/*md
@no-stat

# Lines clamp with lines reserve

This mixin is useful for adding ... for text with more than 1 line and reserve space

## Usage

```scss
.component {
	@include lines-clamp-reserve;
}
```
*/
/* stylelint-disable
	property-no-vendor-prefix,
	property-no-unknown
*/
/*md
@no-stat

# Force repaint

This mixin is useful when repaint needs to be triggered. (sometimes on iOS)

## Usage

```scss
.component {
	@include force_repaint;
}
```
*/
@keyframes repaint {
  from {
    padding-bottom: 0.01px; }
  to {
    padding-bottom: 0; } }

/*md
@no-stat

# Hover-supported

This mixin is designed to address iOS standard behaviour of first tap - hover,
second tap - click that is engaged when control has hover styles applied.

This is critical for functionality like back-top-button. If we apply hover styles as is.
It would be activated only after second tap.

If rules are wrapped into this media it applied only in case if device have fine
pointer mechanism. [See more info](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer).

Please note about mixed input devices - touch screen + mouse + touchpad,
touchpad + trackpoint, touch screen + stylus ("apple pencil") etc. -
sometimes browser do not report it properly, so logic should be builded around
exclusions.

## Usage

```scss
.component {
	@include hover-supported {
		&:hover {
			// Hover styles that should not be applied to touch
		}
	};
}
```
*/
/*md
@no-stat

# Snap scroll

CSS snap scroll functionality applied to different cases.

Designed to use same snap scroll functionality in different components and on particular breakpoints.
Ex: `.b-carousel`, `.b-product_gallery`, `.b-product_slider` etc.

```scss
.b-product_gallery {
	&-thumbs_track {
		@include snap_scroll($direction: y);
	}
	// ...
}
```

[Snap scroll MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scroll_Snap)

*/
/*md

# g-image_container

This is global component designed to hold image in place and preventing from layout bouncing during page load.

It based on `padding-bottom` trick. `padding-bottom` and `padding-top` relative units are based
on parent element `width`. So if you had an element that is 500px wide, and padding-top of 100%,
the padding-top would be 500px. [More info](https://css-tricks.com/aspect-ratio-boxes/)

```scss
.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}

.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}
```

You could change aspect ration in mixin:

```scss
@include g-image_container(_container, 100%);   // 1:1
@include g-image_container(_container, 150%);   // 2:3
@include g-image_container(_container, 133%);   // 3:4
@include g-image_container(_container, 125%);   // 5:4
@include g-image_container(_container, 75%);    // 4:3
@include g-image_container(_container, 66.6%);  // 3:2
@include g-image_container(_container, 56.25%); // 16:9
```

But it is preferable to define only one aspect ration through all images and not change it.

*/
/*md

# g-dialog_backdrop

Dialog window backdrop styles that used in several component.

```scss_example
.b-menu_panel {
	@include media(sm) {
		@include g-dialog_backdrop;
	}
	// ...
}
```
*/
/*md

# g-section_holder

This is global component designed to hold some standalone section of the site
as it wrapped into main container. It could be used not only for standalone blocks
but for page layouts also.

```scss
.b-section {
	background: green;

	&-inner {
		@include g-section_holder;
	}
}
```
*/
/*md

# Headings components

```scss_example
.b-title {
	@include t-heading_1;
}
```

*/
/*md

# Core component for all links

All core and common links styling
stores in this mixin

Be careful changing styles in this mixin!

```scss_example
@mixin t-link_6 {
	@include t-link;
}
```

*/
/*md

# Underlined link component

It extends and modifies `t-link` component
and is a core component for underlined links

```scss_example
@mixin t-link_1 {
	@include t-link_underlined;

	...
}
```
*/
@keyframes link-underline {
  0% {
    background-size: 0 1px; }
  100% {
    background-size: 100% 1px; } }

/*md

# Link type 1

Fully inherits `t-link_underlined` component
with some modifications.

It takes the same list of arguments as `t-link_underlined`
component

```scss_example
.b-some_link {
	@include t-link_1;
}
```
*/
/*md

# Link type 2

Fully inherits `t-link_underlined` component
with some modifications.

It takes the same list of arguments as `t-link_underlined`
component
```scss_example
.b-some_link {
	@include t-link_2;
}
```
*/
/*md

# Link type 3

Link followed with the arrow.
It extends `t-link` component

```scss_example
.b-some_link {
	@include t-link_3;
}
```
*/
@keyframes link-arrow {
  0% {
    width: 5px; }
  100% {
    width: 50px; } }

/*md

# Link type 4

Link with an icon.
It inherits and extends `t-link` component

```scss_example
.b-some_link {
	@include t-link_4;
}
```

Mixin accepts following parameters:
- `$icon` (icon name)
- `$icon-height` (1em by default)
- `$icon-width` (1em by default)

*/
/*md

# Link type 5

Another variation of an underlined link

It takes the same list of arguments as `t-link_underlined`
component

```scss_example
.b-some_link {
	@include t-link_5;
}
```
*/
/*md

# Paragraph components

```scss_example
.b-cart_empty {
	// ...

	&-paragraph {
		@include t-paragraph_1;
	}
}
```

*/
/*md

# List component

Component for styling ordered or unordered lists

List items are also styled.

```scss_example
.b-tips {
	@include t-list;
}

.b-tips {
	@include t-list(ordered);
}
```

*/
/*md

# g-promo*

Mixin for promotion.

```scss_example
.b-cart_promo {
	@include g-promo();

}
```

*/
/*md

# g-button_reset*

Mixin for reset button style.

```scss_example
.b-button {
	@include g-button_reset;
}

```

*/
/*md

# g-button_*

# Mixin for button global styles.

```scss_example
.b-button {
	@include g-button_global($_mod: default);
}
```

*/
/*md

# g-button_main*

# Mixin for main button.

```scss_example
.b-button {
	@include g-button_main($_mod: default);
}
```
*/
/*md

# g-button_alt*

# Mixin for alt button.

```scss_example
.b-button {
	@include g-button_alt;
}
```
*/
/*md

# f-checkbox

Default checkbox element

## Default

```scss_example
	.b-input_checkbox {
		@include f-checkbox($el: root, $mod: default);
	}
```
*/
/*
# Input - type date

Default input element

```scss_example
	.b-some_context_component {
		@include f-input_date($style: default);
	}
```
*/
/*md

# f-input_password

Default input element

## Default input

```scss_example
	.f-input_password {
		@include f-input_password($style: default);
	}
```
*/
/*md

# f-input_reset

Default reset input element

## Default

```scss_example
	.b-input {
		@include f-input_reset;
	}
```
*/
/*md

# f-input_text

Default input element

## Default input

```scss_example
	.f-input_text {
		@include f-input_text($style: default);
	}
```

##Alt input

```scss_example
	.f-input_text {
		@include f-input_text($_mod: alt);
	}
```
*/
/*md

# f-label

Default label element

## Default label

```scss_example
	.f-input_text-label {
		@include f-label($style: default);
	}
```
*/
/*md

# f-radio

Default radio element

## Default

```scss_example
	.f-input_radio {
		@include f-radio;
	}
```
*/
/*md

# f-select

Default label element

## Default

```scss_example
	.f-input_select {
		@include f-select;
	}
```
*/
/*md

# f-textarea

Default textarea element

## Default

```scss_example
	.f-input_textarea {
		@include f-textarea;
	}
```
*/
/*md
# Carousel component
This carousel component is based on a simple built-in scroll functionality.

All layout, sizing, positioning is also controlled in CSS or with the help of HTML attributes.

## Carousel layout options - Number of slides per view

There are two types of configuration:
- static number of slides
- static slide's width

### Static number of slides

To define number of slides per view you can use a CSS custom properties or HTML attributes

#### CSS custom properties
`--carousel-slides_number` and `--carousel-number_of_gaps_per_view`.

We need `--carousel-number_of_gaps_per_view` for accurate calculations of slide's width.

Formulas for these calculations are defined in `g-carousel` mixin.

Default values for these properies are:

```
--carousel-slides_number: 1;
--carousel-number_of_gaps_per_view: 0;
```

```scss_example
.b-custom_carousel {
	@include g-carousel;

	--carousel-slides_number: 3;
	--carousel-number_of_gaps_per_view: 2;
}
```

#### HTML attributes

There's an HTML attribute: `data-slides-number` where you
can define viewport names which have static slides number configuration.

Here's the list of viewport names: sm, md, lg, xl.

To configure exact number of slides for each viewport - use CSS
custom properties in the `style` attribute:

- `--carousel-number_of_gaps_per_view-sm` and `--carousel-slides_number-sm` for Small
- `--carousel-number_of_gaps_per_view-md` and `--carousel-slides_number-md` for Medium
- `--carousel-number_of_gaps_per_view-lg` and `--carousel-slides_number-lg` for Large
- `--carousel-number_of_gaps_per_view-xl` and `--carousel-slides_number-xl` for XLarge

```example
<section class="b-carousel"
	data-slides-number="sm lg"
	style="
		--carousel-number_of_gaps_per_view-sm: 1; --carousel-slides_number-sm: 2;
		--carousel-number_of_gaps_per_view-lg: 3; --carousel-slides_number-lg: 3.5;
	"
>...</section>
```

### Dynamic number of slides

The opposite to the static number of slides is a dynamic number of slides.

You should use it when you don't need the exact number of slides per view
but rather slides width. Visually it will behave this way: when you stretch
viewport size number of slides will increase but the slide's width won't change.

To define slides width you can use CSS custom property or HTML attributes

#### CSS custom properties

There's just one CSS custom property you need: `--carousel-slides-width`

```scss_example
.b-custom_slider {
	--carousel-slides-width: 300px;

	@include media(sm) {
		--carousel-slides-width: 150px;
	}
}
```

#### HTML attributes

There's an HTML attribute: `data-slides-width` where you
can define viewport names which have static slides width configuration.

Here's the list of viewport names: sm, md, lg, xl.

To configure a slides width for each viewport - use CSS
custom properties in the `style` attribute:

- `--carousel-slides_width-sm` for Small
- `--carousel-slides_width-md` for Medium
- `--carousel-slides_width-lg` for Large
- `--carousel-slides_width-xl` for XLarge

```example
<section class="b-carousel"
	data-slides-width="md xl"
	style="
		--carousel-slides_width-md: 250px;
		--carousel-slides_width-xl: 350px;
	"
>...</section>
```

## Carousel layout options - Gutter value between slides

Gap between slides is controlled via CSS custom
property: `--carousel-gutter`

It has the default value: `--carousel-gutter: 0`

## Carousel layout options - Number of rows

Number of rows is controlled via CSS custom
property: `--carousel-rows_number`

It has the default value: `--carousel-rows_number: 1`

*/
/*md

# g-separator

Separator

```scss_example
	b-form {
		@include g-separator;

		// ...
	}
}
```
*/
/*md

# g-accordion

Accordion styles that used in several component.

## Customization by SCSS

This implementation allow to use accordion for one vieport and any other component for rest viewports

```scss_example
.g-accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(item);
	}

	&-title {
		@include g-accordion(control);
	}

	&-content {
		@include g-accordion(content);

		&[aria-hidden='false'] {
			@include g-accordion(content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(content_inner);
	}
}
```

*/
/*md

# g-message*

Mixin for message tile.

```scss_example
.b-message {
	@include g-message;
}
```

*/
body {
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 0.3s; }

.b-header,
.b-menu_panel {
  pointer-events: all;
  visibility: visible; }

.b-header-account,
.b-header-navigation_bottom,
.b-header-navigation_link {
  display: none; }

.b-dialog {
  visibility: hidden; }

.b-shipping_panel-inner,
.b-header_login-panel_inner {
  opacity: 0;
  visibility: hidden; }

/* stylelint-disable selector-max-universal */
*,
*::before,
*::after {
  box-sizing: inherit; }

/* stylelint-enable */
a {
  background-color: transparent; }

ul,
ol,
p {
  list-style: none;
  margin: 0;
  padding: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  margin: 0; }

figure {
  margin: 0; }

img {
  border: none;
  height: auto;
  max-width: 100%; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

button,
input,
select,
textarea {
  color: inherit;
  font: inherit; }

input::-ms-reveal,
input::-ms-clear {
  display: none; }

[hidden] {
  display: none !important; }

html {
  background: #fff;
  color: #423f44;
  direction: ltr;
  font: 14px/1.5 "proxima-soft", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  box-sizing: border-box;
  margin: 0;
  min-width: 310px;
  overflow-y: scroll;
  padding: 0.01px 0 0; }

a {
  color: inherit;
  text-decoration: none; }
  @media not all and (pointer: coarse) {
    a:hover {
      text-decoration: underline; } }

button {
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0; }

img {
  overflow: hidden; }

:target::before {
  content: '';
  display: block;
  height: 80px;
  margin-top: -80px;
  visibility: hidden; }

.l-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  .l-page-content {
    flex-grow: 1; }
  .l-page-footer {
    margin-top: auto; }
  .l-page.m-has_dialog {
    height: auto;
    overflow: hidden;
    position: fixed;
    width: 100%; }
  .l-page.m-has_dialog .l-page-content,
  .l-page.m-has_dialog .l-page-footer {
    overflow-y: scroll; }
  .l-page.m-error_page {
    overflow: auto; }

.b-skip_to {
  background-color: #f6f8fe;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 5px rgba(0, 0, 0, 0.11);
  clip: rect(1px, 1px, 1px, 1px);
  color: #fff;
  display: block;
  left: 0;
  margin: 0 auto;
  max-width: 320px;
  opacity: 0;
  overflow: hidden;
  padding: 15px 60px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 10px;
  transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
  transition-property: opacity, clip;
  z-index: 13; }
  .b-skip_to:focus {
    clip: auto;
    opacity: 1; }

.b-logo {
  display: block;
  transition: width 0.5s;
  width: var(--logo-width, 229px); }
  .b-logo-wrapper {
    display: block;
    padding-bottom: calc(var(--logo-image-height, 80) / var(--logo-image-width, 229) * 100%);
    position: relative;
    transition: padding 0.5s linear; }
  .b-logo-image {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.b-header_login {
  position: relative; }
  .b-header_login-title_link {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 15px; }
    @media screen and (max-width: 1199px) {
      .b-header_login-title_link {
        display: inline-block;
        padding: 10px; } }
    .b-header_login-title_link::before {
      background: var(--icon-color, #805080);
      content: '';
      display: block;
      height: 16px;
      mask: url("./svg-icons/sprite.svg#account");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 13px; }
      @media screen and (min-width: 1200px) {
        .b-header_login-title_link::before {
          margin-right: 10px; } }
    .b-header_login-title_link:hover {
      text-decoration: none; }
  .b-header_login-panel::after {
    background-color: rgba(66, 63, 68, 0.5);
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: visibility 0.4s, opacity 0.4s, left 0.4s;
    visibility: hidden;
    z-index: 1; }
  .b-header_login-panel.m-opened::after {
    opacity: 1;
    visibility: visible; }
  .b-header_login-panel_inner {
    bottom: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 460px;
    z-index: 2;
    right: -100%;
    transition: visibility 0.5s, opacity 0.5s, right 0.5s;
    background: var(--account_panel-background, #fff);
    padding: 45px 50px; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-header_login-panel_inner {
        width: 50%; } }
    @media screen and (max-width: 767px) {
      .b-header_login-panel_inner {
        width: 100%; } }
    .b-header_login-panel_inner.m-image_background {
      background: var(--account_panel-background_image) center center/cover; }
  .b-header_login-panel.m-opened .b-header_login-panel_inner {
    opacity: 1;
    visibility: visible;
    right: 0; }
  .b-header_login .b-button_close {
    position: absolute;
    right: 5px;
    top: 5px; }

.b-header_message {
  bottom: 0;
  font-family: "proxima-soft", sans-serif;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 24px;
  padding: 30px 15px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 17; }
  .b-header_message-link {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: bold;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 0;
    font-weight: 400; }
    .b-header_message-link:visited {
      color: var(--color-link-visited, #805080); }
    .b-header_message-link:hover {
      text-decoration: none; }
    .b-header_message-link.m-disabled {
      color: var(--color-link-disabled, #75717C);
      pointer-events: none; }
    .b-header_message-link:visited {
      color: var(--color-link-visited, #805080); }
    .b-header_message-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-header_message-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-header_message-link.m-disabled {
      color: var(--color-link-disabled, #75717C);
      pointer-events: none; }
  .b-header_message.m-javascript {
    background: #faeded;
    color: #CA1E36; }
  .b-header_message.m-cookies {
    background: #423f44;
    color: #592761; }

.b-minicart_panel {
  position: relative;
  z-index: 9; }
  .b-minicart_panel::after {
    background-color: rgba(66, 63, 68, 0.5);
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: visibility 0.4s, opacity 0.4s, left 0.4s;
    visibility: hidden;
    z-index: 1; }
  .b-minicart_panel.m-opened::after {
    opacity: 1;
    visibility: visible; }
  .b-minicart_panel-container {
    bottom: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 460px;
    z-index: 2;
    right: -100%;
    transition: visibility 0.5s, opacity 0.5s, right 0.5s;
    background: #fff; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-minicart_panel-container {
        width: 50%; } }
    @media screen and (max-width: 767px) {
      .b-minicart_panel-container {
        width: 100%; } }
  .b-minicart_panel.m-opened .b-minicart_panel-container {
    opacity: 1;
    visibility: visible;
    right: 0; }

/*md
# Carousel

## Core customization options

`b-carousel` uses `g-carousel` component and so all core customizations
options are available (which can be found
[here](./02-components-carousel-g-carousel.html))

## Carousel variations

There are predefined modifications for `b-carousel` which will be described
in details later. Here's a brief list:

### Preset modifications (only one at a time)
 * m-preset-products - used for "Recently viewed products" slider
 * m-preset-recommendation - used for "Products recommendations" slider

### Layout modifications (only one at a time)
 * m-layout-controls_above - Controls and title above carousel
 * m-layout-controls_left_side - Controls and title on the left carousel on the right side
 * m-layout-controls_top_left - Controls on the top left side (without title and subtitle block)
 * m-layout-controls_vertically_centered - Controls on the sides, vertically centered (without title and subtitle block)
 * m-layout-pagination_and_controls - Controls vertically centered and Pagination over the track with slides (without title and subtitle block)

### Theme modifications
 * m-inverse - Inverse control colors

## Preset - Products

### Description

This preset is used for "Recently viewed products" slider - together with
`m-layout-controls_above` layout modification.

### Details

- XL: Slide width equals 297px;
- LG: 4.5 slides per view;
- MD: Slide width equals 246px;
- SM: 1.3 slides per view.

```html_example
<style>
.b-carousel img { width: 100%; }
</style>
<section
	class="b-carousel
		m-prev_visible m-next_visible m-inited
		m-layout-controls_above m-preset-products
	"
	data-widget="carousel"
>
	<div class="b-carousel-title_container">
		<h2 class="b-carousel-title">Carousel Title</h2>
		<div class="b-carousel-sub_title">Subtitle</div>
	</div>
	<div class="b-carousel-controls">
		<button
			class="b-carousel-control m-previous"
			tabindex="-1"
			aria-hidden="true"
			title="Previous"
			data-ref="elemPrevButton"
			data-event-click="scrollBack"
		></button>
		<button
			class="b-carousel-control m-next"
			tabindex="-1"
			aria-hidden="true"
			title="Next"
			data-ref="elemNextButton"
			data-event-click="scrollNext"
		></button>
	</div>
	<div
		class="b-carousel-track"
		data-ref="elemCarouselTrack"
		data-event-scroll.passive="onScroll"
		data-event-touchstart="onScroll"
		data-event-mousedown.prevent="onMouseDown"
		data-event-mouseup="onMouseUp"
	>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
	</div>
	<div class="b-carousel-pagination_container" data-ref="pagination"></div>
	<script type="template/mustache" data-ref="template">
		<div class="b-carousel-pagination_container" data-ref="pagination">
			{{#pagination}}
			<div
				class="b-carousel-pagination_control"
				data-page="{{page}}"
				data-event-click.prevent="handlePaginationClick"
			>
			</div>
			{{/pagination}}
		</div>
	</script>
</section>
```

## Preset - Recommendations

### Description

This preset is used for "Product recommendations" slider - together with
`m-layout-controls_left_side` layout modification.

### Details

- XL: Slide width equals 297px;
- LG: 3 slides per view. First slide width is 1.5 of a regular slide
	and 2 rows;
- MD: Slide width equals 246px;
- SM: 1.3 slides per view.

```html_example
<section
	class="b-carousel
		m-prev_visible m-next_visible m-inited
		m-layout-controls_left_side m-preset-recommendation
	"
	data-widget="carousel"
>
	<div class="b-carousel-title_container">
		<h2 class="b-carousel-title">Carousel Title</h2>
		<div class="b-carousel-sub_title">Subtitle</div>
	</div>
	<div class="b-carousel-controls">
		<button
			class="b-carousel-control m-previous"
			tabindex="-1"
			aria-hidden="true"
			title="Previous"
			data-ref="elemPrevButton"
			data-event-click="scrollBack"
		></button>
		<button
			class="b-carousel-control m-next"
			tabindex="-1"
			aria-hidden="true"
			title="Next"
			data-ref="elemNextButton"
			data-event-click="scrollNext"
		></button>
	</div>
	<div
		class="b-carousel-track"
		data-ref="elemCarouselTrack"
		data-event-scroll.passive="onScroll"
		data-event-touchstart="onScroll"
		data-event-mousedown.prevent="onMouseDown"
		data-event-mouseup="onMouseUp"
	>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/400x600" alt="Slide" />
		</div>
	</div>
	<div class="b-carousel-pagination_container" data-ref="pagination"></div>
	<script type="template/mustache" data-ref="template">
		<div class="b-carousel-pagination_container" data-ref="pagination">
			{{#pagination}}
			<div
				class="b-carousel-pagination_control"
				data-page="{{page}}"
				data-event-click.prevent="handlePaginationClick"
			>
			</div>
			{{/pagination}}
		</div>
	</script>
</section>
```

## Modification - Layout - Controls above

### Description

This modification defines layout for carousel
elements, title and subtitle:
- title and subtitle at the left side, above the carousel slides
- previous and next buttons at the right side, above the carousel slides

```html_example
<section
	class="b-carousel
		m-prev_visible m-next_visible m-inited
		m-layout-controls_above
	"
	data-widget="carousel"
>
	<div class="b-carousel-title_container">
		<h2 class="b-carousel-title">Carousel Title</h2>
		<div class="b-carousel-sub_title">Subtitle</div>
	</div>
	<div class="b-carousel-controls">
		<button
			class="b-carousel-control m-previous"
			tabindex="-1"
			aria-hidden="true"
			title="Previous"
			data-ref="elemPrevButton"
			data-event-click="scrollBack"
		></button>
		<button
			class="b-carousel-control m-next"
			tabindex="-1"
			aria-hidden="true"
			title="Next"
			data-ref="elemNextButton"
			data-event-click="scrollNext"
		></button>
	</div>
	<div
		class="b-carousel-track"
		data-ref="elemCarouselTrack"
		data-event-scroll.passive="onScroll"
		data-event-touchstart="onScroll"
		data-event-mousedown.prevent="onMouseDown"
		data-event-mouseup="onMouseUp"
	>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
	</div>
	<div class="b-carousel-pagination_container" data-ref="pagination"></div>
	<script type="template/mustache" data-ref="template">
		<div class="b-carousel-pagination_container" data-ref="pagination">
			{{#pagination}}
			<div
				class="b-carousel-pagination_control"
				data-page="{{page}}"
				data-event-click.prevent="handlePaginationClick"
			>
			</div>
			{{/pagination}}
		</div>
	</script>
</section>
```

## Modification - Layout - Controls left side

### Description

This layout modification positions prev/next controls,
title, subtitle, carousel slides:
- title and subtitle: top left side;
- prev/next controls: below title and subtitle;
- carousel slides: at the right side.

```html_example
<section
	class="b-carousel
		m-prev_visible m-next_visible m-inited
		m-layout-controls_left_side
	"
	data-widget="carousel"
>
	<div class="b-carousel-title_container">
		<h2 class="b-carousel-title">Carousel Title</h2>
		<div class="b-carousel-sub_title">Subtitle</div>
	</div>
	<div class="b-carousel-controls">
		<button
			class="b-carousel-control m-previous"
			tabindex="-1"
			aria-hidden="true"
			title="Previous"
			data-ref="elemPrevButton"
			data-event-click="scrollBack"
		></button>
		<button
			class="b-carousel-control m-next"
			tabindex="-1"
			aria-hidden="true"
			title="Next"
			data-ref="elemNextButton"
			data-event-click="scrollNext"
		></button>
	</div>
	<div
		class="b-carousel-track"
		data-ref="elemCarouselTrack"
		data-event-scroll.passive="onScroll"
		data-event-touchstart="onScroll"
		data-event-mousedown.prevent="onMouseDown"
		data-event-mouseup="onMouseUp"
	>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
	</div>
	<div class="b-carousel-pagination_container" data-ref="pagination"></div>
	<script type="template/mustache" data-ref="template">
		<div class="b-carousel-pagination_container" data-ref="pagination">
			{{#pagination}}
			<div
				class="b-carousel-pagination_control"
				data-page="{{page}}"
				data-event-click.prevent="handlePaginationClick"
			>
			</div>
			{{/pagination}}
		</div>
	</script>
</section>
```

## Modification - Layout - Controls top left

### Description

Positions prev/next controls above the carousel slides
at the left side.

```html_example
<section
	class="b-carousel
		m-prev_visible m-next_visible m-inited
		m-layout-controls_top_left
	"
	data-widget="carousel"
>
	<div class="b-carousel-controls">
		<button
			class="b-carousel-control m-previous"
			tabindex="-1"
			aria-hidden="true"
			title="Previous"
			data-ref="elemPrevButton"
			data-event-click="scrollBack"
		></button>
		<button
			class="b-carousel-control m-next"
			tabindex="-1"
			aria-hidden="true"
			title="Next"
			data-ref="elemNextButton"
			data-event-click="scrollNext"
		></button>
	</div>
	<div
		class="b-carousel-track"
		data-ref="elemCarouselTrack"
		data-event-scroll.passive="onScroll"
		data-event-touchstart="onScroll"
		data-event-mousedown.prevent="onMouseDown"
		data-event-mouseup="onMouseUp"
	>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
	</div>
	<div class="b-carousel-pagination_container" data-ref="pagination"></div>
	<script type="template/mustache" data-ref="template">
		<div class="b-carousel-pagination_container" data-ref="pagination">
			{{#pagination}}
			<div
				class="b-carousel-pagination_control"
				data-page="{{page}}"
				data-event-click.prevent="handlePaginationClick"
			>
			</div>
			{{/pagination}}
		</div>
	</script>
</section>
```

## Modification - Layout - Controls vertically centered

### Description

Positions prev/next controls in front of carousel slides:

- previous button at the left side vertically at the middle;
- next button at the right side and vertically at the middle.

```html_example
<section
	class="b-carousel
		m-prev_visible m-next_visible m-inited
		m-layout-controls_vertically_centered
	"
	data-widget="carousel"
>
	<div class="b-carousel-controls">
		<button
			class="b-carousel-control m-previous"
			tabindex="-1"
			aria-hidden="true"
			title="Previous"
			data-ref="elemPrevButton"
			data-event-click="scrollBack"
		></button>
		<button
			class="b-carousel-control m-next"
			tabindex="-1"
			aria-hidden="true"
			title="Next"
			data-ref="elemNextButton"
			data-event-click="scrollNext"
		></button>
	</div>
	<div
		class="b-carousel-track"
		data-ref="elemCarouselTrack"
		data-event-scroll.passive="onScroll"
		data-event-touchstart="onScroll"
		data-event-mousedown.prevent="onMouseDown"
		data-event-mouseup="onMouseUp"
	>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
	</div>
	<div class="b-carousel-pagination_container" data-ref="pagination"></div>
	<script type="template/mustache" data-ref="template">
		<div class="b-carousel-pagination_container" data-ref="pagination">
			{{#pagination}}
			<div
				class="b-carousel-pagination_control"
				data-page="{{page}}"
				data-event-click.prevent="handlePaginationClick"
			>
			</div>
			{{/pagination}}
		</div>
	</script>
</section>
```

## Modification - Layout - Controls vertically centered and Pagination Over slider

### Description

Positions prev/next controls in front of carousel slides:

- previous button at the left side vertically at the middle;
- next button at the right side and vertically at the middle.

Positions pagination conrols in front of the carousel slides
at the bottom and horizontally center aligned.

```html_example
<section
	class="b-carousel
		m-prev_visible m-next_visible m-inited
		m-layout-pagination_and_controls
	"
	data-widget="carousel"
>
	<div class="b-carousel-controls">
		<button
			class="b-carousel-control m-previous"
			tabindex="-1"
			aria-hidden="true"
			title="Previous"
			data-ref="elemPrevButton"
			data-event-click="scrollBack"
		></button>
		<button
			class="b-carousel-control m-next"
			tabindex="-1"
			aria-hidden="true"
			title="Next"
			data-ref="elemNextButton"
			data-event-click="scrollNext"
		></button>
	</div>
	<div
		class="b-carousel-track"
		data-ref="elemCarouselTrack"
		data-event-scroll.passive="onScroll"
		data-event-touchstart="onScroll"
		data-event-mousedown.prevent="onMouseDown"
		data-event-mouseup="onMouseUp"
	>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200" alt="Slide" />
		</div>
	</div>
	<div class="b-carousel-pagination_container" data-ref="pagination"></div>
	<script type="template/mustache" data-ref="template">
		<div class="b-carousel-pagination_container" data-ref="pagination">
			{{#pagination}}
			<div
				class="b-carousel-pagination_control"
				data-page="{{page}}"
				data-event-click.prevent="handlePaginationClick"
			>
			</div>
			{{/pagination}}
		</div>
	</script>
</section>
```

## Modification - Theme - Inverse

### Description

This modification inverts prev/next controls colors
so it better works with dark slides.

```html_example
<section
	class="b-carousel
		m-prev_visible m-next_visible m-inited
		m-layout-controls_vertically_centered
		m-inverse
	"
	data-widget="carousel"
>
	<div class="b-carousel-controls">
		<button
			class="b-carousel-control m-previous"
			tabindex="-1"
			aria-hidden="true"
			title="Previous"
			data-ref="elemPrevButton"
			data-event-click="scrollBack"
		></button>
		<button
			class="b-carousel-control m-next"
			tabindex="-1"
			aria-hidden="true"
			title="Next"
			data-ref="elemNextButton"
			data-event-click="scrollNext"
		></button>
	</div>
	<div
		class="b-carousel-track"
		data-ref="elemCarouselTrack"
		data-event-scroll.passive="onScroll"
		data-event-touchstart="onScroll"
		data-event-mousedown.prevent="onMouseDown"
		data-event-mouseup="onMouseUp"
	>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200/000/ddd" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200/000/ddd" alt="Slide" />
		</div>
		<div class="b-carousel-slide" tabindex="0">
			<img src="https://placehold.it/600x200/000/ddd" alt="Slide" />
		</div>
	</div>
	<div class="b-carousel-pagination_container" data-ref="pagination"></div>
	<script type="template/mustache" data-ref="template">
		<div class="b-carousel-pagination_container" data-ref="pagination">
			{{#pagination}}
			<div
				class="b-carousel-pagination_control"
				data-page="{{page}}"
				data-event-click.prevent="handlePaginationClick"
			>
			</div>
			{{/pagination}}
		</div>
	</script>
</section>
```
*/
.b-carousel {
  /* stylelint-disable-next-line  length-zero-no-unit */
  --carousel-slides-width-calculated: calc((100% - var(--carousel-gutter, 0px) * var(--carousel-number_of_gaps_per_view, 0)) / var(--carousel-slides_number, 1));
  --carousel-slides-width: var(--carousel-slides-width-calculated);
  position: relative;
  --product_tile-width: 100%;
  --carousel-gutter: 15px;
  display: grid;
  grid-template: 'title' 'controls' 'carousel' 'pagination' / 1fr;
  /* stylelint-disable-next-line */
  /* stylelint-disable-next-line */ }
  @media screen and (max-width: 767px) {
    .b-carousel[data-slides-number~='sm'] {
      --carousel-number_of_gaps_per_view: var(--carousel-number_of_gaps_per_view-sm, 0);
      --carousel-slides_number: var(--carousel-slides_number-sm, 1); }
    .b-carousel[data-slides-width~='sm'] {
      --carousel-slides-width: var(--carousel-slides_width-sm, 100%); } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-carousel[data-slides-number~='md'] {
      --carousel-number_of_gaps_per_view: var(--carousel-number_of_gaps_per_view-md, 0);
      --carousel-slides_number: var(--carousel-slides_number-md, 1); }
    .b-carousel[data-slides-width~='md'] {
      --carousel-slides-width: var(--carousel-slides_width-md, 100%); } }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-carousel[data-slides-number~='lg'] {
      --carousel-number_of_gaps_per_view: var(--carousel-number_of_gaps_per_view-lg, 0);
      --carousel-slides_number: var(--carousel-slides_number-lg, 1); }
    .b-carousel[data-slides-width~='lg'] {
      --carousel-slides-width: var(--carousel-slides_width-lg, 100%); } }
  @media screen and (min-width: 1441px) {
    .b-carousel[data-slides-number~='xl'] {
      --carousel-number_of_gaps_per_view: var(--carousel-number_of_gaps_per_view-xl, 0);
      --carousel-slides_number: var(--carousel-slides_number-xl, 1); }
    .b-carousel[data-slides-width~='xl'] {
      --carousel-slides-width: var(--carousel-slides_width-xl, 100%); } }
  .b-carousel-title_container {
    grid-area: title;
    margin-bottom: 30px; }
  .b-carousel-title {
    font: 400 40px/48px "hwt-artz", serif;
    letter-spacing: 0.5px; }
    @media screen and (max-width: 1199px) {
      .b-carousel-title {
        font-size: 32px;
        letter-spacing: 0.4px;
        line-height: 40px; } }
  .b-carousel-sub_title {
    font: 16px/1.5 "proxima-soft", sans-serif;
    letter-spacing: 0.2px;
    margin-top: 20px; }
  .b-carousel-track {
    scrollbar-width: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    -ms-overflow-style: none;
    /* IE */
    overflow-x: auto;
    overscroll-behavior-x: contain;
    scroll-behavior: smooth;
    -ms-scroll-chaining: none;
    scroll-snap-type: x proximity;
    scrollbar-width: none;
    /* FF */
    display: grid;
    gap: var(--carousel-gutter, 0);
    grid-auto-columns: var(--carousel-slides-width, max-content);
    grid-auto-flow: column;
    grid-template-rows: repeat(var(--carousel-rows_number, 1), auto);
    position: relative;
    scroll-snap-destination: 0% 100%;
    scroll-snap-points-x: repeat(100%);
    scroll-snap-type: x mandatory;
    grid-area: carousel; }
    .b-carousel-track::-webkit-scrollbar {
      display: none; }
  .b-carousel-slide {
    display: grid;
    scroll-snap-align: start; }
  .b-carousel.m-page_designer .b-carousel-slide {
    justify-items: stretch; }
  .b-carousel-controls {
    align-self: end;
    grid-area: controls;
    margin-bottom: 30px;
    grid-area: controls; }
  .b-carousel:not(.m-prev_visible):not(.m-next_visible) .b-carousel-controls {
    display: none; }
  .b-carousel-control {
    appearance: none;
    cursor: pointer;
    display: none;
    user-select: none;
    z-index: 1;
    --icon-color: var(--carousel-control-color, #805080);
    align-items: center;
    border: 2px solid var(--carousel-control-color, #805080);
    border-radius: 50%;
    height: var(--carousel-control-size, 48px);
    justify-content: center;
    text-align: center;
    transition: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
    transition-property: background-color, opacity;
    width: var(--carousel-control-size, 48px); }
    @media (hover: hover) and (pointer: fine) {
      .b-carousel-control:hover {
        box-shadow: 0 3px 5px rgba(128, 80, 128, 0.25); } }
  .b-carousel.m-inited .b-carousel-control {
    display: initial;
    display: inline-flex; }
    @media screen and (max-width: 1199px) {
      .b-carousel.m-inited .b-carousel-control {
        display: none; } }
  .b-carousel-control[disabled],
  .b-carousel.m-no_scroll .b-carousel-control {
    pointer-events: none;
    opacity: 0.3; }
  .b-carousel-control.m-next::before {
    background: var(--icon-color, #805080);
    content: '';
    display: block;
    height: 1em;
    mask: url("./svg-icons/sprite.svg#arrow-right");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 1em; }
  .b-carousel-control.m-previous {
    margin-right: 10px; }
    .b-carousel-control.m-previous::before {
      background: var(--icon-color, #805080);
      content: '';
      display: block;
      height: 1em;
      mask: url("./svg-icons/sprite.svg#arrow-left");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 1em; }
  .b-carousel-pagination_container {
    z-index: 1;
    grid-area: pagination;
    margin-top: 30px;
    text-align: center;
    width: 100%; }
  .b-carousel-pagination_control {
    appearance: none;
    cursor: pointer;
    display: inline-block;
    background-color: var(--carousel-pagination_control-color, #fff);
    border-radius: 50%;
    height: 10px;
    margin: 0 12px;
    width: 10px; }
    .b-carousel-pagination_control.m-current {
      pointer-events: none;
      --carousel-pagination_control-color: #805080; }
  @media screen and (min-width: 1440px) {
    .b-carousel.m-preset-products {
      --carousel-slides-width: 378.75px; } }
  @media screen and (min-width: 1200px) and (max-width: 1439px) {
    .b-carousel.m-preset-products {
      --carousel-number_of_gaps_per_view: 3;
      --carousel-slides_number: 4.36; } }
  @media screen and (min-width: 1024px) and (max-width: 1199px) {
    .b-carousel.m-preset-products {
      --carousel-number_of_gaps_per_view: 2;
      --carousel-slides_number: 3.047; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .b-carousel.m-preset-products {
      --carousel-slides-width: 236px; } }
  @media screen and (max-width: 767px) {
    .b-carousel.m-preset-products {
      --carousel-number_of_gaps_per_view: 1;
      --carousel-slides_number: 1; } }
  @media screen and (max-width: 767px) {
    .b-carousel.m-preset-products.double-product {
      --carousel-number_of_gaps_per_view: 1;
      --carousel-slides_number: 2.095; } }
  @media screen and (min-width: 1441px) {
    .b-carousel.m-preset-recommendation {
      --carousel-slides-width: 297px; } }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-carousel.m-preset-recommendation {
      --carousel-number_of_gaps_per_view: 4;
      --carousel-slides_number: 4.5; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-carousel.m-preset-recommendation {
      --carousel-slides-width: 246px; } }
  @media screen and (max-width: 767px) {
    .b-carousel.m-preset-recommendation {
      --carousel-number_of_gaps_per_view: 1;
      --carousel-slides_number: 1; } }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-carousel.m-preset-recommendation {
      --carousel-slides_number: 3;
      --carousel-number_of_gaps_per_view: 2; } }
  @media screen and (min-width: 1200px) {
    .b-carousel.m-preset-recommendation .b-carousel-slide {
      grid-row: 1 / 2; } }
  @media screen and (min-width: 1200px) {
    .b-carousel.m-preset-recommendation .b-carousel-track {
      grid-template-columns: var(--carousel-slides-width);
      grid-template-rows: auto 1fr; } }
  .b-carousel.m-layout-controls_above {
    display: grid;
    grid-template: 'title' 'carousel' / 1fr; }
    @media screen and (min-width: 1200px) {
      .b-carousel.m-layout-controls_above {
        grid-template: "title controls" "carousel carousel"/1fr 150px; } }
  .b-carousel.m-layout-controls_above .b-carousel-pagination_container {
    display: none; }
  .b-carousel.m-layout-controls_left_side {
    display: grid;
    grid-template: 'title' 'carousel' / 1fr; }
    @media screen and (min-width: 1200px) {
      .b-carousel.m-layout-controls_left_side {
        grid-template: "title carousel" fit-content(140px) "controls carousel" max-content/1fr 2fr; } }
  .b-carousel.m-layout-controls_left_side .b-carousel-controls {
    align-self: auto; }
  .b-carousel.m-layout-controls_left_side .b-carousel-pagination_container {
    display: none; }
  .b-carousel.m-layout-controls_top_left {
    display: grid;
    grid-template: 'controls' 'carousel' / 1fr; }
    @media screen and (min-width: 1200px) {
      .b-carousel.m-layout-controls_top_left {
        column-gap: 75px;
        grid-template: 'controls' 'carousel' / 1fr; } }
  .b-carousel.m-layout-controls_top_left .b-carousel-control {
    --carousel-control-size: 40px; }
  .b-carousel.m-layout-controls_top_left .b-carousel-title_container,
  .b-carousel.m-layout-controls_top_left .b-carousel-pagination_container {
    display: none; }
  .b-carousel.m-layout-controls_vertically_centered .b-carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .b-carousel.m-layout-controls_vertically_centered .b-carousel-control.m-next {
    right: var(--carousel-controls-offset, 35px); }
  .b-carousel.m-layout-controls_vertically_centered .b-carousel-control.m-previous {
    left: var(--carousel-controls-offset, 35px); }
  .b-carousel.m-layout-controls_vertically_centered .b-carousel-title_container {
    display: none; }
  @media screen and (min-width: 1200px) {
    .b-carousel.m-layout-controls_vertically_centered .b-carousel-pagination_container {
      display: none; } }
  .b-carousel.m-layout-controls_vertically_centered .b-carousel-controls {
    margin: 0; }
  .b-carousel.m-layout-pagination_and_controls .b-carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .b-carousel.m-layout-pagination_and_controls .b-carousel-controls {
    margin: 0; }
  .b-carousel.m-layout-pagination_and_controls .b-carousel-control.m-next {
    right: var(--carousel-controls-offset, 35px); }
  .b-carousel.m-layout-pagination_and_controls .b-carousel-control.m-previous {
    left: var(--carousel-controls-offset, 35px); }
  .b-carousel.m-layout-pagination_and_controls .b-carousel-pagination_container {
    bottom: 30px;
    left: 0;
    position: absolute; }
  .b-carousel.m-layout-pagination_and_controls .b-carousel-title_container {
    display: none; }
  .b-carousel.m-inverse .b-carousel-control {
    --carousel-control-color: #fff; }

@media screen and (max-width: 1199px) {
  .b-carousel.m-inited[data-enable-autoplay="true"] .b-carousel-control {
    display: initial;
    display: inline-flex;
    position: relative; }
    .b-carousel.m-inited[data-enable-autoplay="true"] .b-carousel-control.m-previous {
      margin-left: 10px;
      left: 0; }
    .b-carousel.m-inited[data-enable-autoplay="true"] .b-carousel-control.m-next {
      right: 0; } }

/*md

# Breadcrumbs

```html example
<nav class="b-breadcrumbs">
	<ul class="b-breadcrumbs-list">
		<li class="b-breadcrumbs-item ">
			<a class="b-breadcrumbs-link" href="#">Home</a>
		</li>
		<li class="b-breadcrumbs-item ">
			<a class="b-breadcrumbs-link" href="#">Women</a>
		</li>
		<li class="b-breadcrumbs-item m-current">
			<span class="b-breadcrumbs-link m-current">Accessories</span>
		</li>
	</ul>
</nav>
```

*/
.b-breadcrumbs {
  scrollbar-width: none;
  overflow-x: auto;
  scrollbar-width: none; }
  .b-breadcrumbs::-webkit-scrollbar {
    display: none; }
  .b-breadcrumbs-list {
    display: flex; }
  .b-breadcrumbs-item {
    display: flex;
    line-height: 1.2;
    white-space: nowrap; }
    .b-breadcrumbs-item:first-child {
      display: none; }
    @media screen and (max-width: 767px) {
      .b-breadcrumbs-item:not(:nth-last-child(2)) {
        display: flex;
        margin: 0;
        opacity: 0;
        width: 0;
        z-index: -1; } }
    @media screen and (min-width: 768px) {
      .b-breadcrumbs-item:not(.m-current)::after {
        background: var(--icon-color, #805080);
        content: '';
        display: block;
        height: 12px;
        mask: url("./svg-icons/sprite.svg#separator");
        mask-position: 50%;
        mask-repeat: no-repeat;
        mask-size: 100%;
        width: 1px;
        background: #805080;
        margin: 2px 15px 0; } }
  .b-breadcrumbs-link {
    color: var(--color-link, #805080);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 7px; }
    .b-breadcrumbs-link:visited {
      color: var(--color-link-visited, #805080); }
    .b-breadcrumbs-link:hover {
      text-decoration: none; }
    .b-breadcrumbs-link.m-disabled {
      color: var(--color-link-disabled, #75717C);
      pointer-events: none; }
    .b-breadcrumbs-link:visited {
      color: var(--color-link-visited, currentColor); }
    .b-breadcrumbs-link:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-breadcrumbs-link:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-breadcrumbs-link.m-disabled {
      color: var(--color-link-disabled, #75717C);
      pointer-events: none; }
    @media screen and (max-width: 767px) {
      .b-breadcrumbs-link {
        --breadcrumb-icon-offset: 20px;
        background: none;
        margin-left: var(--breadcrumb-icon-offset);
        padding-bottom: 0;
        position: relative; }
        .b-breadcrumbs-link::before {
          background: var(--icon-color, #805080);
          content: '';
          display: block;
          height: 1em;
          mask: url("./svg-icons/sprite.svg#arrow-left");
          mask-position: 50%;
          mask-repeat: no-repeat;
          mask-size: 100%;
          width: 1em;
          left: calc(-1 * var(--breadcrumb-icon-offset));
          position: absolute;
          top: 50%;
          transform: translateY(-50%); } }
    .b-breadcrumbs-link.m-current {
      background: none;
      color: var(--color-cur-breadcrumb, #423f44);
      cursor: default;
      font-weight: normal; }
      .b-breadcrumbs-link.m-current::after {
        display: none; }

.b-nav_aux {
  margin: 0 auto;
  max-width: 1720px;
  padding-left: 80px;
  padding-right: 80px;
  color: #75717C;
  margin-bottom: 40px;
  margin-top: 40px; }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .b-nav_aux {
      max-width: 1400px; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-nav_aux {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-nav_aux {
      padding-left: 15px;
      padding-right: 15px; } }
  @media screen and (max-width: 767px) {
    .b-nav_aux {
      margin-bottom: 30px;
      margin-top: 30px; } }

.b-categories_navigation {
  padding: 0 15px; }
  .b-categories_navigation-list_1 {
    display: flex;
    justify-content: center; }
    @media screen and (min-width: 1200px) {
      .b-categories_navigation-list_1 {
        padding: 30px 0 0; } }
    @media screen and (max-width: 1199px) {
      .b-categories_navigation-list_1 {
        flex-direction: column; } }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation-item_1 {
      display: flex;
      flex-direction: column;
      padding: 0 25px; }
      .b-categories_navigation-item_1::after {
        border-bottom: 1px solid #423f44;
        content: '';
        transition: 0.3s width;
        width: 0;
        z-index: 1; }
      .b-categories_navigation-item_1:hover::after {
        width: 100%; } }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation-item_1.m-with_subcategories::before {
      background-color: rgba(66, 63, 68, 0.5);
      content: '';
      height: calc(100vh - var(--header-height));
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 100%;
      transition: opacity 0.3s linear, visibility 0.3s linear;
      visibility: hidden; } }
  .b-categories_navigation-item_1.m-with_subcategories:hover::before {
    opacity: 1;
    visibility: visible; }
  @media screen and (max-width: 1199px) {
    .b-categories_navigation-item_1 + .b-categories_navigation-item_1 {
      border-top: 1px solid #C7C3CA; } }
  .b-categories_navigation-link_1 {
    display: inline-block;
    font: 700 24px/40px "proxima-soft", sans-serif;
    letter-spacing: 0;
    text-transform: capitalize; }
    @media screen and (min-width: 1200px) {
      .b-categories_navigation-link_1 {
        padding: 0 0 20px; } }
    @media screen and (max-width: 1199px) {
      .b-categories_navigation-link_1 {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 25px 10px; }
        .b-categories_navigation-link_1::after {
          background: var(--icon-color, #805080);
          content: '';
          display: block;
          height: 13px;
          mask: url("./svg-icons/sprite.svg#arrow-right-thin");
          mask-position: 50%;
          mask-repeat: no-repeat;
          mask-size: 100%;
          width: 7px; } }
    .b-categories_navigation-link_1:hover {
      text-decoration: none; }
  .b-categories_navigation-back_link {
    background-color: #fff;
    border-bottom: 1px solid #C7C3CA; }
    @media screen and (min-width: 1200px) {
      .b-categories_navigation-back_link {
        display: none; } }
  .b-categories_navigation-back_link_cta {
    align-items: center;
    display: flex;
    font-size: 16px;
    padding: 25px;
    text-transform: uppercase;
    width: 100%; }
    .b-categories_navigation-back_link_cta::before {
      background: var(--icon-color, #805080);
      content: '';
      display: block;
      height: 13px;
      mask: url("./svg-icons/sprite.svg#arrow-left-thin");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 7px;
      margin-right: 15px; }
  .b-categories_navigation-show_all_products {
    display: block;
    padding: 20px 30px;
    text-transform: uppercase; }
    @media screen and (min-width: 1200px) {
      .b-categories_navigation-show_all_products {
        display: none; } }
  @media screen and (max-width: 1199px) {
    .b-categories_navigation-group_1 {
      height: calc(100% - var(--header-height));
      left: -100%;
      opacity: 0;
      position: fixed;
      top: var(--header-height);
      transition: visibility 0.4s, opacity 0.4s, left 0.4s;
      visibility: hidden;
      width: 100%; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-categories_navigation-group_1 {
      min-width: 375px;
      width: 50%; } }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation-group_1 {
      background: #fff;
      border-top: 2px solid #C7C3CA;
      left: 0;
      margin-top: -2px;
      opacity: 0;
      padding: 25px 0 50px;
      position: absolute;
      right: 0;
      top: 100%;
      transition: opacity 0.3s;
      visibility: hidden; } }
  @media screen and (max-width: 1199px) {
    .b-categories_navigation-group_1 {
      background: #fff;
      overflow: auto;
      overscroll-behavior: contain;
      z-index: 1; } }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation-list_2 {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      padding: 0 50px; } }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation-list_2 > .b-categories_navigation-item_2.m-view_all {
      display: none; } }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation-link_2 {
      display: block;
      font-size: 16px;
      font-weight: 600;
      line-height: 25px;
      margin-bottom: 15px;
      text-transform: uppercase; } }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation-item_1:hover .b-categories_navigation-group_1, .b-categories_navigation-group_1[aria-hidden='false'] {
      opacity: 1;
      visibility: visible; } }
  @media screen and (max-width: 1199px) {
    .b-categories_navigation-item_1.m-active .b-categories_navigation-group_1 {
      left: 0;
      opacity: 1;
      transition: visibility, opacity 0.4s, left 0.4s;
      visibility: visible; } }
  .b-categories_navigation-group_2 {
    background: #fff; }
    @media screen and (max-width: 1199px) {
      .b-categories_navigation-group_2 {
        height: calc(100% - var(--header-height));
        left: -100%;
        opacity: 0;
        position: fixed;
        top: var(--header-height);
        transition: visibility 0.4s, opacity 0.4s, left 0.4s;
        visibility: hidden;
        width: 100%; } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .b-categories_navigation-group_2 {
        min-width: 375px;
        width: 50%; } }
    @media screen and (max-width: 1199px) {
      .b-categories_navigation-group_2 {
        background: #fff;
        overflow: auto;
        overscroll-behavior: contain;
        z-index: 1; } }
  @media screen and (max-width: 1199px) {
    .b-categories_navigation-item_2.m-active .b-categories_navigation-group_2 {
      left: 0;
      opacity: 1;
      transition: visibility, opacity 0.4s, left 0.4s;
      visibility: visible; } }
  @media screen and (max-width: 1199px) {
    .b-categories_navigation-list_2, .b-categories_navigation-list_3 {
      padding: 0 15px; } }
  .b-categories_navigation.m-content_only .b-categories_navigation-list_2 {
    display: block; }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation.m-3_col .b-categories_navigation-list_2 {
      grid-template-columns: repeat(6, 1fr); } }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation.m-1_col .b-categories_navigation-list_2 {
      grid-template-columns: repeat(3, 1fr); } }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation-item_3 {
      margin-bottom: 10px; } }
  @media screen and (max-width: 1199px) {
    .b-categories_navigation-item_2:not(.m-view_all), .b-categories_navigation-item_3:not(.m-view_all) {
      border-bottom: 1px solid #C7C3CA; } }
  @media screen and (max-width: 1199px) {
    .b-categories_navigation-link_2, .b-categories_navigation-link_3 {
      font-size: 16px;
      line-height: 1;
      padding: 30px 10px;
      text-transform: uppercase; } }
  .b-categories_navigation-link_2.m-view_all, .b-categories_navigation-link_3.m-view_all {
    color: var(--color-link, inherit);
    cursor: pointer;
    display: inline;
    font-weight: 600;
    letter-spacing: 0.16px;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 90%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    padding-bottom: 7px;
    display: inline-block; }
    .b-categories_navigation-link_2.m-view_all:visited, .b-categories_navigation-link_3.m-view_all:visited {
      color: var(--color-link-visited, #805080); }
    .b-categories_navigation-link_2.m-view_all:hover, .b-categories_navigation-link_3.m-view_all:hover {
      text-decoration: none; }
    .b-categories_navigation-link_2.m-view_all.m-disabled, .b-categories_navigation-link_3.m-view_all.m-disabled {
      color: var(--color-link-disabled, #75717C);
      pointer-events: none; }
    .b-categories_navigation-link_2.m-view_all:visited, .b-categories_navigation-link_3.m-view_all:visited {
      color: var(--color-link-visited, #805080); }
    .b-categories_navigation-link_2.m-view_all:hover, .b-categories_navigation-link_3.m-view_all:hover {
      text-decoration: none; }
      @media (hover: hover) and (pointer: fine) {
        .b-categories_navigation-link_2.m-view_all:hover, .b-categories_navigation-link_3.m-view_all:hover {
          animation: link-underline 0.2s cubic-bezier(0.5, 0.2, 0.65, 0.7) forwards; } }
    .b-categories_navigation-link_2.m-view_all.m-disabled, .b-categories_navigation-link_3.m-view_all.m-disabled {
      color: var(--color-link-disabled, #75717C);
      pointer-events: none; }
    @media screen and (max-width: 1199px) {
      .b-categories_navigation-link_2.m-view_all, .b-categories_navigation-link_3.m-view_all {
        margin-left: 10px;
        padding-left: 0;
        padding-right: 0;
        text-transform: none; } }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation-link_3 {
      font-size: 14px;
      line-height: 25px; } }
  @media screen and (max-width: 1199px) {
    .b-categories_navigation-link_2:not(.m-view_all), .b-categories_navigation-link_3:not(.m-view_all) {
      align-items: center;
      display: flex;
      justify-content: space-between; }
      .b-categories_navigation-link_2:not(.m-view_all)::after, .b-categories_navigation-link_3:not(.m-view_all)::after {
        background: var(--icon-color, #805080);
        content: '';
        display: block;
        height: 13px;
        mask: url("./svg-icons/sprite.svg#arrow-right-thin");
        mask-position: 50%;
        mask-repeat: no-repeat;
        mask-size: 100%;
        width: 7px; } }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation-group_1.m-custom_menu {
      display: grid;
      padding: 25px 50px 50px; } }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation-group_1.m-custom_menu.m-1x1 {
      grid-template-columns: 1fr 1fr; } }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation-group_1.m-custom_menu.m-1x2 {
      grid-template-columns: 1fr 2fr; } }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation-group_1.m-custom_menu .b-categories_navigation-list_2 {
      padding: 0 20px 0 0; } }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation-group_1.m-1x1 .b-categories_navigation-list_2 {
      grid-template-columns: repeat(3, 1fr); } }
  @media screen and (min-width: 1200px) {
    .b-categories_navigation-group_1.m-1x2 .b-categories_navigation-list_2 {
      grid-template-columns: 1fr; } }

.b-header_promo {
  background-color: #F7F6F7;
  max-height: 0;
  opacity: 0;
  position: relative;
  visibility: hidden; }
  .b-header_promo.m-visible {
    max-height: 80px;
    opacity: 1;
    padding: 10px 30px;
    visibility: visible; }
  .b-header_promo-content {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    text-align: center; }
  .b-header_promo-close {
    cursor: pointer;
    font-size: 0;
    padding: 10px;
    position: absolute;
    right: 5px;
    top: 5px; }
    .b-header_promo-close::before {
      background: var(--icon-color, #805080);
      content: '';
      display: block;
      height: 10px;
      mask: url("./svg-icons/sprite.svg#close");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 10px; }

.b-header {
  --header-space_without_header_height: calc(100vh - var(--header-height, 0));
  display: grid;
  grid-template: [start promo-s background-s] auto [promo-e links-s logo-s utility-s] auto [links-e logo-e utility-e nav-s] auto [end background-e nav-e]/[start promo-s background-s] minmax(var(--grid-margin), calc((100vw - 1720px) / 2)) [links-s nav-s] 1fr [] 1fr [] 1fr [links-e] 1fr [logo-s] 1fr [] 1fr [] 1fr [] 1fr [logo-e] 1fr [utility-s] 1fr [] 1fr [] 1fr [utility-e nav-e] minmax(var(--grid-margin), calc((100vw - 1720px) / 2)) [end promo-e background-e];
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 7; }
  @media screen and (max-width: 1199px) {
    .b-header {
      grid-template: [start promo-s background-s] auto [promo-e hamburger-s logo-s utility-s] auto [hamburger-e logo-e utility-e nav-s background-e] 1fr [end nav-e]/[start promo-s nav-s background-s] minmax(var(--grid-margin), calc((100vw - 1720px) / 2)) [hamburger-s] auto [hamburger-e logo-s] 1fr [logo-e utility-s] auto [end utility-e] minmax(var(--grid-margin), calc((100vw - 1720px) / 2)) [end promo-e nav-e background-e];
      height: 100%;
      pointer-events: none; }
      .b-header::after {
        background-color: rgba(66, 63, 68, 0.5);
        content: '';
        grid-area: nav-s/nav-s/nav-e/nav-e;
        opacity: 0;
        transition: opacity 0.5s linear 0.2s; } }
  .b-header-background {
    background-color: var(--color-header-background, #fff);
    border-bottom: 1px solid #C7C3CA;
    grid-area: background-s/background-s/background-e/background-e;
    transition: background-color 0.5s, color 0.5s; }
  .b-header-promo {
    grid-area: promo-s/promo-s/promo-e/promo-e;
    pointer-events: all; }
  .b-header-hamburger_cta_container {
    align-self: center;
    grid-area: hamburger-s/hamburger-s/hamburger-e/hamburger-e;
    line-height: 1;
    pointer-events: all; }
    @media screen and (min-width: 1200px) {
      .b-header-hamburger_cta_container {
        display: none; } }
  .b-header-hamburger_cta {
    cursor: pointer;
    margin-left: -5px;
    padding: 15px 5px; }
    .b-header-hamburger_cta::before {
      background: var(--icon-color, #805080);
      content: '';
      display: block;
      height: 15px;
      mask: url("./svg-icons/sprite.svg#hamburger");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 17px; }
  .b-header-close_hamburger {
    cursor: pointer;
    display: none;
    left: 5px;
    padding: 15px 10px;
    pointer-events: all;
    position: absolute;
    top: -57px; }
    .b-header-close_hamburger::before {
      background: var(--icon-color, #805080);
      content: '';
      display: block;
      height: 16px;
      mask: url("./svg-icons/sprite.svg#close");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 16px; }
  .b-header-links {
    display: flex;
    grid-area: links-s/links-s/links-e/links-e;
    justify-content: flex-start; }
    @media screen and (min-width: 1200px) {
      .b-header-links {
        margin-left: -15px;
        margin-top: 35px; } }
  .b-header-links_item {
    align-items: center;
    display: flex; }
    .b-header-links_item:hover {
      text-decoration: none; }
    .b-header-links_item.m-stores {
      padding: 15px; }
      @media screen and (max-width: 1199px) {
        .b-header-links_item.m-stores {
          padding: 10px; } }
      .b-header-links_item.m-stores::before {
        background: var(--icon-color, #805080);
        content: '';
        display: block;
        height: 17px;
        mask: url("./svg-icons/sprite.svg#location");
        mask-position: 50%;
        mask-repeat: no-repeat;
        mask-size: 100%;
        width: 13px;
        margin-right: 10px; }
  .b-header-logo {
    grid-area: logo-s/logo-s/logo-e/logo-e; }
    @media screen and (min-width: 1200px) {
      .b-header-logo {
        margin: 35px auto 0;
        text-align: center; } }
    @media screen and (max-width: 1199px) {
      .b-header-logo {
        align-items: center;
        display: flex;
        height: 70px;
        margin-left: 25px;
        pointer-events: all; } }
  .b-header-utility {
    align-items: center;
    display: flex;
    grid-area: utility-s/utility-s/utility-e/utility-e;
    justify-content: flex-end;
    margin-right: -10px; }
    @media screen and (min-width: 1200px) {
      .b-header-utility {
        margin-right: -15px;
        margin-top: 35px; } }
    @media screen and (max-width: 1199px) {
      .b-header-utility {
        pointer-events: all; } }
  .b-header-utility_item {
    align-items: center;
    display: flex; }
    @media screen and (max-width: 1199px) {
      .b-header-utility_item {
        font-size: 0; } }
    .b-header-utility_item:hover {
      text-decoration: none; }
    .b-header-utility_item.m-search {
      cursor: pointer;
      padding: 15px; }
      @media screen and (max-width: 1199px) {
        .b-header-utility_item.m-search {
          padding: 10px; } }
      .b-header-utility_item.m-search::before {
        background: var(--icon-color, #805080);
        content: '';
        display: block;
        height: 17px;
        mask: url("./svg-icons/sprite.svg#search");
        mask-position: 50%;
        mask-repeat: no-repeat;
        mask-size: 100%;
        width: 17px; }
        @media screen and (min-width: 1200px) {
          .b-header-utility_item.m-search::before {
            margin-right: 10px; } }
    .b-header-utility_item.m-favorites {
      padding: 15px; }
      .b-header-utility_item.m-favorites::after {
        background: var(--icon-color, #805080);
        content: '';
        display: block;
        height: 16px;
        mask: url("./svg-icons/sprite.svg#heart");
        mask-position: 50%;
        mask-repeat: no-repeat;
        mask-size: 100%;
        width: 17px; }
    .b-header-utility_item.m-favorites_active::after {
      background: var(--icon-color, #805080);
      content: '';
      display: block;
      height: 16px;
      mask: url("./svg-icons/sprite.svg#wishlist-active");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 17px;
      background: #805080; }
  .b-header-utility_item.m-stores, .b-header-utility_item.m-ship_to {
    display: none; }
  .b-header-utility_item.m-stores {
    padding: 15px; }
    @media screen and (max-width: 1199px) {
      .b-header-utility_item.m-stores {
        padding: 10px; } }
  .b-header-navigation {
    grid-area: nav-s/nav-s/nav-e/nav-e; }
    @media screen and (max-width: 1199px) {
      .b-header-navigation {
        height: 100%;
        position: relative;
        transition: visibility 0.5s linear;
        visibility: hidden; } }
  .b-header-navigation_bottom .b-customer_service_info {
    margin: 35px 25px; }
  @media screen and (max-width: 1199px) {
    .b-header-navigation_inner {
      background: #fff;
      height: 100%;
      left: -100%;
      opacity: 0;
      overflow: auto;
      pointer-events: all;
      position: absolute;
      transition: opacity 0.5s linear, left 0.5s linear;
      width: 100%;
      z-index: 2; } }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .b-header-navigation_inner {
      min-width: 375px;
      width: 50%; } }
  .b-header-navigation_link {
    padding: 0 15px; }
  @media screen and (max-width: 1199px) {
    .b-header-navigation_link_inner {
      border-top: 1px solid #C7C3CA;
      display: block;
      padding: 30px 10px; } }
  .b-header-navigation_link.m-stores .b-header-navigation_link_inner {
    align-items: center;
    display: flex; }
    .b-header-navigation_link.m-stores .b-header-navigation_link_inner::before {
      background: var(--icon-color, #805080);
      content: '';
      display: block;
      height: 15px;
      mask: url("./svg-icons/sprite.svg#location");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 11px;
      margin-right: 10px; }
  @media screen and (max-width: 1199px) {
    .b-header-account {
      background-color: #fff;
      border-bottom: 1px solid #75717C;
      border-top: 1px solid #75717C;
      padding: 35px 25px; } }
  @media screen and (min-width: 1200px) {
    .b-header.m-left_aligned {
      grid-template: [start promo-s background-s] auto [promo-e logo-s nav-s utility-s] auto [end logo-e nav-e utility-e background-e]/[start promo-s background-s] 30px [logo-s] auto [logo-e nav-s] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [nav-e utility-s] 1fr [] 1fr [] 1fr [utility-e] 30px [end promo-e background-e]; } }
  @media screen and (max-width: 1199px) {
    .b-header.m-left_aligned {
      grid-template: [start promo-s background-s] auto [promo-e hamburger-s logo-s utility-s] auto [hamburger-e logo-e utility-e background-e nav-s] 1fr [end nav-e]/[start promo-s background-s nav-s] 15px [hamburger-s] 1fr [hamburger-e logo-s] auto [logo-e utility-s] 1fr [end utility-e] 15px [end background-e promo-e nav-e]; } }
  .b-header.m-left_aligned .b-categories_navigation {
    padding: 0 25px; }
    .b-header.m-left_aligned .b-categories_navigation-list_1 {
      justify-content: flex-start; }
      @media screen and (min-width: 1200px) {
        .b-header.m-left_aligned .b-categories_navigation-list_1 {
          padding: 40px 0 0; } }
    @media screen and (min-width: 1200px) {
      .b-header.m-left_aligned .b-categories_navigation-link_1 {
        padding: 0 0 35px; } }
  .b-header.m-left_aligned .b-header-logo {
    align-self: center;
    margin: 0;
    text-align: left; }
    @media screen and (max-width: 1199px) {
      .b-header.m-left_aligned .b-header-logo {
        align-items: center;
        display: flex;
        height: 52px;
        margin: 15px auto; } }
  .b-header.m-left_aligned .b-header-utility {
    margin-top: 0; }
  .b-header.m-left_aligned .b-header-utility_item.m-stores,
  .b-header.m-left_aligned .b-header-utility_item.m-ship_to {
    display: block;
    font-size: 14px;
    max-width: 150px;
    opacity: 1;
    transition: max-width 0.3s, font-size 0.3s, margin 0.3s;
    visibility: visible; }
  .b-header.m-left_aligned .b-header-utility_item.m-search,
  .b-header.m-left_aligned .b-header-utility_item.m-stores,
  .b-header.m-left_aligned .b-header_login-title {
    font-size: 0; }
  .b-header.m-left_aligned .b-header-utility_item.m-stores::before {
    background: var(--icon-color, #805080);
    content: '';
    display: block;
    height: 17px;
    mask: url("./svg-icons/sprite.svg#location");
    mask-position: 50%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 13px; }
  .b-header.m-left_aligned .b-header-links {
    display: none; }
  .b-header.m-left_aligned .b-header-close_hamburger {
    top: -58px; }
  .b-header.m-left_aligned .b-header_login-title_link::before,
  .b-header.m-left_aligned .b-header-utility_item.m-search::before {
    margin-right: 0; }
  .b-header.m-transparent:not(.m-active):not(.m-stuck):not(.m-hamburger_opened) {
    --color-header-background: transparent; }
    @media not all and (pointer: coarse) {
      .b-header.m-transparent:not(.m-active):not(.m-stuck):not(.m-hamburger_opened):hover {
        color: inherit; }
        .b-header.m-transparent:not(.m-active):not(.m-stuck):not(.m-hamburger_opened):hover .b-logo-image {
          fill: currentColor; }
        .b-header.m-transparent:not(.m-active):not(.m-stuck):not(.m-hamburger_opened):hover .b-header_promo {
          background-color: #F7F6F7; }
        .b-header.m-transparent:not(.m-active):not(.m-stuck):not(.m-hamburger_opened):hover .b-header-background {
          background-color: #fff;
          border-color: #C7C3CA; } }
  .b-header.m-transparent:not(.m-inverted):not(.m-active):not(.m-stuck):not(.m-hamburger_opened) {
    color: #423f44; }
    .b-header.m-transparent:not(.m-inverted):not(.m-active):not(.m-stuck):not(.m-hamburger_opened) .b-logo-image {
      fill: #423f44;
      transition: fill 0.5s; }
  .b-header.m-transparent.m-inverted:not(.m-active):not(.m-stuck):not(.m-hamburger_opened) {
    color: #fff; }
    .b-header.m-transparent.m-inverted:not(.m-active):not(.m-stuck):not(.m-hamburger_opened) .b-logo-image {
      fill: #fff;
      transition: fill 0.5s; }
  .b-header.m-transparent:not(.m-active):not(.m-stuck):not(.m-hamburger_opened) .b-header-background {
    border-color: rgba(117, 113, 124, 0.15); }
  .b-header.m-transparent:not(.m-active):not(.m-stuck):not(.m-hamburger_opened) .b-header_promo {
    background-color: #F7F6F7; }
  @media screen and (max-width: 1199px) {
    .b-header.m-hamburger_opened::after {
      opacity: 1; } }
  .b-header.m-hamburger_opened .b-header-close_hamburger {
    display: block; }
  .b-header.m-hamburger_opened .b-header-hamburger_cta_container {
    opacity: 0;
    visibility: hidden; }
  .b-header.m-hamburger_opened .b-header-navigation {
    visibility: visible; }
    .b-header.m-hamburger_opened .b-header-navigation::before {
      display: block; }
  @media screen and (max-width: 1199px) {
    .b-header.m-hamburger_opened .b-header-navigation_inner {
      left: 0;
      opacity: 1; } }
  .b-header.m-stuck {
    --header-space_without_header_height: calc(100vh - var(--header-sticky-height, 0)); }
    @media screen and (min-width: 1200px) {
      .b-header.m-stuck {
        grid-template: [start background-s promo-s] auto [promo-e logo-s nav-s utility-s] auto [end background-e logo-e nav-e utility-e]/[start promo-s background-s] 30px [logo-s] 1fr [] 1fr [logo-e nav-s] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [nav-e utility-s] 1fr [] 1fr [utility-e] 30px [end promo-e background-e]; } }
  .b-header.m-stuck .b-header_promo {
    max-height: 0;
    opacity: 0;
    padding: 0;
    visibility: hidden; }
  @media screen and (min-width: 1200px) {
    .b-header.m-stuck .b-header-logo {
      align-self: center;
      margin: 0;
      text-align: left; } }
  @media screen and (max-width: 1199px) {
    .b-header.m-stuck.m-left_aligned .b-header-logo {
      margin: 15px; } }
  .b-header.m-stuck .b-header-utility {
    margin-top: 0; }
  .b-header.m-stuck .b-header-utility_item.m-search,
  .b-header.m-stuck .b-header-utility_item.m-account {
    font-size: 0; }
  .b-header.m-stuck .b-header_login-title_link::before,
  .b-header.m-stuck .b-header-utility_item.m-search::before {
    margin-right: 0; }
  .b-header.m-stuck .b-header-links {
    display: none; }
  @media screen and (min-width: 1200px) {
    .b-header.m-stuck.m-left_aligned {
      grid-template: [start promo-s background-s] auto [promo-e logo-s nav-s utility-s] auto [end logo-e nav-e utility-e background-e]/[start promo-s background-s] 30px [logo-s] auto [logo-e nav-s] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [nav-e utility-s] 1fr [] 1fr [] 1fr [utility-e] 30px [end promo-e background-e]; } }
  .b-header.m-stuck.m-left_aligned .b-header-utility_item.m-stores,
  .b-header.m-stuck.m-left_aligned .b-header-utility_item.m-ship_to {
    font-size: 0;
    margin: 0;
    max-width: 0;
    opacity: 0;
    padding: 0;
    visibility: hidden; }
  @media screen and (min-width: 1200px) {
    .b-header.m-stuck .b-categories_navigation-list_1 {
      padding: 25px 0 0; } }
  @media screen and (min-width: 1200px) {
    .b-header.m-stuck .b-categories_navigation-link_1 {
      padding: 0 0 25px; } }
  @media screen and (min-width: 1200px) {
    .b-header.m-stuck.m-left_aligned .b-categories_navigation-list_1 {
      padding: 35px 0 0; } }
  @media screen and (min-width: 1200px) {
    .b-header.m-stuck.m-left_aligned .b-categories_navigation-link_1 {
      padding: 0 0 35px; } }
  @media screen and (min-width: 1200px) {
    .b-header .b-categories_navigation-group_1 {
      max-height: var(--header-space_without_header_height);
      overflow: auto; } }
  @media screen and (max-width: 1199px) {
    .b-header.m-stuck .b-categories_navigation-group_1,
    .b-header.m-stuck .b-categories_navigation-group_2 {
      height: var(--header-space_without_header_height);
      top: var(--header-sticky-height); } }
  .b-header.m-stuck .b-categories_navigation-item_1.m-with_subcategories::before {
    height: var(--header-space_without_header_height); }
  .b-header .b-logo {
    --logo-width: 229px;
    --logo-image-height: 80;
    --logo-image-width: 229; }
    @media screen and (max-width: 1199px) {
      .b-header .b-logo {
        --logo-width: 129px; } }
  .b-header.m-left_aligned .b-logo {
    --logo-width: 52px;
    --logo-image-height: 150;
    --logo-image-width: 109; }
  .b-header.m-stuck .b-logo {
    --logo-width: 129px; }
    @media screen and (max-width: 1199px) {
      .b-header.m-stuck .b-logo {
        --logo-width: 129px; } }
  .b-header.m-left_aligned.m-stuck .b-logo {
    --logo-width: 52px;
    --logo-image-height: 130; }

.b-header-navigation_link.m-stores {
  font: 700 24px/40px "proxima-soft", sans-serif;
  letter-spacing: 0;
  text-transform: capitalize; }

@media screen and (max-width: 1199px) {
  .b-ship_to {
    align-items: center;
    display: flex;
    justify-content: space-between; }
    .b-ship_to::after {
      background: var(--icon-color, #805080);
      content: '';
      display: block;
      height: 13px;
      mask: url("./svg-icons/sprite.svg#arrow-right-thin");
      mask-position: 50%;
      mask-repeat: no-repeat;
      mask-size: 100%;
      width: 7px;
      margin-left: 5px; } }

.b-ship_to-link {
  align-items: center;
  cursor: pointer;
  display: flex; }
  @media screen and (min-width: 1200px) {
    .b-ship_to-link {
      padding: 15px; }
      .b-ship_to-link::after {
        background: var(--icon-color, #805080);
        content: '';
        display: block;
        height: 7px;
        mask: url("./svg-icons/sprite.svg#arrow-down");
        mask-position: 50%;
        mask-repeat: no-repeat;
        mask-size: 100%;
        width: 4px;
        margin-left: 5px; } }
  @media screen and (max-width: 1199px) {
    .b-ship_to-link {
      align-items: center;
      display: flex;
      font: 700 24px/40px "proxima-soft", sans-serif;
      letter-spacing: 0;
      text-transform: capitalize; }
      .b-ship_to-link::before {
        background: var(--icon-color, #805080);
        content: '';
        display: block;
        height: 15px;
        mask: url("./svg-icons/sprite.svg#box");
        mask-position: 50%;
        mask-repeat: no-repeat;
        mask-size: 100%;
        width: 14px;
        margin-right: 10px; } }
  .b-ship_to-link:hover {
    text-decoration: none; }

.b-ship_to-state {
  border-bottom: 1px dashed currentColor;
  margin-left: 5px; }

.b-browsing_only-row_1 {
  font-weight: bold;
  margin-bottom: 20px; }
